// source: pbtypes/tools/cloud_api/dock.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_dock_dock_core_fiducial_pb = require('../../../pbtypes/dock/dock_core/fiducial_pb.js');
goog.object.extend(proto, pbtypes_dock_dock_core_fiducial_pb);
var pbtypes_gen_dock_client_takeoff_surface_pb = require('../../../pbtypes/gen/dock_client/takeoff_surface_pb.js');
goog.object.extend(proto, pbtypes_gen_dock_client_takeoff_surface_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_release_pb = require('../../../pbtypes/tools/cloud_api/release_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_release_pb);
var pbtypes_tools_cloud_api_site_pb = require('../../../pbtypes/tools/cloud_api/site_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_site_pb);
goog.exportSymbol('proto.cloud_api.Dock', null, global);
goog.exportSymbol('proto.cloud_api.Docks', null, global);
goog.exportSymbol('proto.cloud_api.TakeoffPoint', null, global);
goog.exportSymbol('proto.cloud_api.UpdateDockRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Dock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.Dock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Dock.displayName = 'proto.cloud_api.Dock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Docks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Docks.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Docks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Docks.displayName = 'proto.cloud_api.Docks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateDockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateDockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateDockRequest.displayName = 'proto.cloud_api.UpdateDockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.TakeoffPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.TakeoffPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.TakeoffPoint.displayName = 'proto.cloud_api.TakeoffPoint';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Dock.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Dock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Dock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Dock.toObject = function(includeInstance, msg) {
  var f, obj = {
dockId: jspb.Message.getFieldWithDefault(msg, 1, ""),
skydioSerial: jspb.Message.getFieldWithDefault(msg, 2, ""),
disabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
organizationId: jspb.Message.getFieldWithDefault(msg, 8, ""),
linkedVehicleId: jspb.Message.getFieldWithDefault(msg, 9, ""),
linkedAccessPointSerial: jspb.Message.getFieldWithDefault(msg, 16, ""),
releaseOverride: (f = msg.getReleaseOverride()) && pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.toObject(includeInstance, f),
dockFiducial: (f = msg.getDockFiducial()) && pbtypes_dock_dock_core_fiducial_pb.DockFiducial.toObject(includeInstance, f),
dockType: jspb.Message.getFieldWithDefault(msg, 12, 0),
uuid: jspb.Message.getFieldWithDefault(msg, 13, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 14, ""),
isSimulator: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
takeoffPoint: (f = msg.getTakeoffPoint()) && proto.cloud_api.TakeoffPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Dock}
 */
proto.cloud_api.Dock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Dock;
  return proto.cloud_api.Dock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Dock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Dock}
 */
proto.cloud_api.Dock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDockId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkydioSerial(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedVehicleId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedAccessPointSerial(value);
      break;
    case 10:
      var value = new pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.deserializeBinaryFromReader);
      msg.setReleaseOverride(value);
      break;
    case 11:
      var value = new pbtypes_dock_dock_core_fiducial_pb.DockFiducial;
      reader.readMessage(value,pbtypes_dock_dock_core_fiducial_pb.DockFiducial.deserializeBinaryFromReader);
      msg.setDockFiducial(value);
      break;
    case 12:
      var value = /** @type {!proto.dock_client.TakeoffSurface.Enum} */ (reader.readEnum());
      msg.setDockType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSimulator(value);
      break;
    case 17:
      var value = new proto.cloud_api.TakeoffPoint;
      reader.readMessage(value,proto.cloud_api.TakeoffPoint.deserializeBinaryFromReader);
      msg.setTakeoffPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Dock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Dock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Dock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Dock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDockId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkydioSerial();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLinkedVehicleId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLinkedAccessPointSerial();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getReleaseOverride();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.serializeBinaryToWriter
    );
  }
  f = message.getDockFiducial();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_dock_dock_core_fiducial_pb.DockFiducial.serializeBinaryToWriter
    );
  }
  f = message.getDockType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsSimulator();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getTakeoffPoint();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.cloud_api.TakeoffPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string dock_id = 1;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skydio_serial = 2;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getSkydioSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setSkydioSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool disabled = 3;
 * @return {boolean}
 */
proto.cloud_api.Dock.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string organization_id = 8;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string linked_vehicle_id = 9;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getLinkedVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setLinkedVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string linked_access_point_serial = 16;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getLinkedAccessPointSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setLinkedAccessPointSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional DeviceReleaseOverride release_override = 10;
 * @return {?proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.Dock.prototype.getReleaseOverride = function() {
  return /** @type{?proto.cloud_api.DeviceReleaseOverride} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride, 10));
};


/**
 * @param {?proto.cloud_api.DeviceReleaseOverride|undefined} value
 * @return {!proto.cloud_api.Dock} returns this
*/
proto.cloud_api.Dock.prototype.setReleaseOverride = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.clearReleaseOverride = function() {
  return this.setReleaseOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Dock.prototype.hasReleaseOverride = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional dock_core.DockFiducial dock_fiducial = 11;
 * @return {?proto.dock_core.DockFiducial}
 */
proto.cloud_api.Dock.prototype.getDockFiducial = function() {
  return /** @type{?proto.dock_core.DockFiducial} */ (
    jspb.Message.getWrapperField(this, pbtypes_dock_dock_core_fiducial_pb.DockFiducial, 11));
};


/**
 * @param {?proto.dock_core.DockFiducial|undefined} value
 * @return {!proto.cloud_api.Dock} returns this
*/
proto.cloud_api.Dock.prototype.setDockFiducial = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.clearDockFiducial = function() {
  return this.setDockFiducial(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Dock.prototype.hasDockFiducial = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional dock_client.TakeoffSurface.Enum dock_type = 12;
 * @return {!proto.dock_client.TakeoffSurface.Enum}
 */
proto.cloud_api.Dock.prototype.getDockType = function() {
  return /** @type {!proto.dock_client.TakeoffSurface.Enum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.dock_client.TakeoffSurface.Enum} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setDockType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string uuid = 13;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string display_name = 14;
 * @return {string}
 */
proto.cloud_api.Dock.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_simulator = 15;
 * @return {boolean}
 */
proto.cloud_api.Dock.prototype.getIsSimulator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.setIsSimulator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional TakeoffPoint takeoff_point = 17;
 * @return {?proto.cloud_api.TakeoffPoint}
 */
proto.cloud_api.Dock.prototype.getTakeoffPoint = function() {
  return /** @type{?proto.cloud_api.TakeoffPoint} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.TakeoffPoint, 17));
};


/**
 * @param {?proto.cloud_api.TakeoffPoint|undefined} value
 * @return {!proto.cloud_api.Dock} returns this
*/
proto.cloud_api.Dock.prototype.setTakeoffPoint = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Dock} returns this
 */
proto.cloud_api.Dock.prototype.clearTakeoffPoint = function() {
  return this.setTakeoffPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Dock.prototype.hasTakeoffPoint = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Docks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Docks.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Docks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Docks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Docks.toObject = function(includeInstance, msg) {
  var f, obj = {
docksList: jspb.Message.toObjectList(msg.getDocksList(),
    proto.cloud_api.Dock.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Docks}
 */
proto.cloud_api.Docks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Docks;
  return proto.cloud_api.Docks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Docks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Docks}
 */
proto.cloud_api.Docks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Dock;
      reader.readMessage(value,proto.cloud_api.Dock.deserializeBinaryFromReader);
      msg.addDocks(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Docks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Docks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Docks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Docks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Dock.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dock docks = 1;
 * @return {!Array<!proto.cloud_api.Dock>}
 */
proto.cloud_api.Docks.prototype.getDocksList = function() {
  return /** @type{!Array<!proto.cloud_api.Dock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Dock, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Dock>} value
 * @return {!proto.cloud_api.Docks} returns this
*/
proto.cloud_api.Docks.prototype.setDocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Dock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Dock}
 */
proto.cloud_api.Docks.prototype.addDocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Dock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Docks} returns this
 */
proto.cloud_api.Docks.prototype.clearDocksList = function() {
  return this.setDocksList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Docks.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Docks} returns this
*/
proto.cloud_api.Docks.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Docks} returns this
 */
proto.cloud_api.Docks.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Docks.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateDockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateDockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateDockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateDockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
dock: (f = msg.getDock()) && proto.cloud_api.Dock.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateDockRequest}
 */
proto.cloud_api.UpdateDockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateDockRequest;
  return proto.cloud_api.UpdateDockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateDockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateDockRequest}
 */
proto.cloud_api.UpdateDockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Dock;
      reader.readMessage(value,proto.cloud_api.Dock.deserializeBinaryFromReader);
      msg.setDock(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateDockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateDockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateDockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateDockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDock();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Dock.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dock dock = 1;
 * @return {?proto.cloud_api.Dock}
 */
proto.cloud_api.UpdateDockRequest.prototype.getDock = function() {
  return /** @type{?proto.cloud_api.Dock} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Dock, 1));
};


/**
 * @param {?proto.cloud_api.Dock|undefined} value
 * @return {!proto.cloud_api.UpdateDockRequest} returns this
*/
proto.cloud_api.UpdateDockRequest.prototype.setDock = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateDockRequest} returns this
 */
proto.cloud_api.UpdateDockRequest.prototype.clearDock = function() {
  return this.setDock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateDockRequest.prototype.hasDock = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateDockRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateDockRequest} returns this
*/
proto.cloud_api.UpdateDockRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateDockRequest} returns this
 */
proto.cloud_api.UpdateDockRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateDockRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.TakeoffPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.TakeoffPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.TakeoffPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.TakeoffPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
site: (f = msg.getSite()) && pbtypes_tools_cloud_api_site_pb.Site.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.TakeoffPoint}
 */
proto.cloud_api.TakeoffPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.TakeoffPoint;
  return proto.cloud_api.TakeoffPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.TakeoffPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.TakeoffPoint}
 */
proto.cloud_api.TakeoffPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_site_pb.Site;
      reader.readMessage(value,pbtypes_tools_cloud_api_site_pb.Site.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.TakeoffPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.TakeoffPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.TakeoffPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.TakeoffPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_site_pb.Site.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.TakeoffPoint.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.TakeoffPoint} returns this
 */
proto.cloud_api.TakeoffPoint.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Site site = 2;
 * @return {?proto.cloud_api.Site}
 */
proto.cloud_api.TakeoffPoint.prototype.getSite = function() {
  return /** @type{?proto.cloud_api.Site} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_site_pb.Site, 2));
};


/**
 * @param {?proto.cloud_api.Site|undefined} value
 * @return {!proto.cloud_api.TakeoffPoint} returns this
*/
proto.cloud_api.TakeoffPoint.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.TakeoffPoint} returns this
 */
proto.cloud_api.TakeoffPoint.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.TakeoffPoint.prototype.hasSite = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.cloud_api);
